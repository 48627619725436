<template>
  <el-card id="paylog">
    <header>
      <h1>充值日志</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap" v-if="roleName == '管理员'">
            <span>公司ID</span>
            <el-input
              v-model="form.id"
              placeholder="请输入公司ID"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '管理员'">
            <span>公司名称</span>
            <el-input
              v-model="form.realname"
              placeholder="请输入公司名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            size="small"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getList2"
            class="searchBtn"
            v-if="realname == 'admin'"
            size="small"
            >{{ adminSum_Z }}
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getList3"
            class="searchBtn"
            v-if="realname == 'admin'"
            size="small"
            >{{ adminSum_F }}
          </el-button>

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="small"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-dblclick="ellDblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="用户编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="用户名称"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="充值后余额"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="oldMoney"
          label="充值前余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注信息"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="财务确认状态"
          align="center"
          v-if="realname == 'admin'"
        >
          <template #default="scope">
            <span v-if="scope.row.status == 0">未查看</span>
            <span v-if="scope.row.status == 1">已查看</span>
          </template>
        </el-table-column>
        <el-table-column
          label="财务查看"
          width="100"
          align="center"
          style="dispaly: flex"
          v-if="realname == 'admin'"
        >
          <template #default="scope">
            <el-button
              type="primary"
              @click="unsubscribe(scope)"
              v-if="realname == 'admin'"
              size="small"
              >确认</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 双击 弹出框 -->
    <div id="ticket">
      <el-dialog title="开票" :visible.sync="userLogTicketFlag" width="80%">
        <el-form ref="dataAddForm" :model="form_ticket" label-width="100px">
          <el-row>
            <el-form-item label="代理编号" prop="userId">
              <el-input
                style="width: 100%"
                v-model="form_ticket.userId"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="代理名称" prop="realname">
              <el-input
                style="width: 100%"
                v-model="form_ticket.realname"
                disabled
              ></el-input>
            </el-form-item>
            <el-col>
              <el-row>
                <el-col>
                  <el-form-item label="日志充值时间" prop="createTime">
                    <el-input
                      v-model="form_ticket.createTime"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-form-item label="充值后金额" prop="oldMoney">
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.oldMoney"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item label="充值前金额" prop="money">
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.money"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item label="充值金额" prop="addMoney">
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.addMoney"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-row>
            </el-col>
            <el-form-item label="充值备注" prop="remarks">
              <el-input
                v-model="form_ticket.remarks"
                style="width: 100%"
                type="textarea"
                disabled
              ></el-input>
            </el-form-item>
          </el-row>
          <br />
          <span style="color: #98fd98; font-size: 20px"
            >-----------------------------------------------------------------------------开票信息-----------------------------------------------------------------------------</span
          >
          <br />
          <br /><br />
          <el-row
            style="display: flex; align-items: center; margin-bottom: 40px"
          >
            <h3>新增开票记录：</h3>
            <el-row>
              <el-col>
                <el-form-item
                  label="开票名称"
                  prop="ticket_money"
                  style="margin-bottom: 0px"
                >
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.name"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="税号"
                  prop="remark"
                  style="margin-bottom: 0px"
                >
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.dutyNum"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item
                  label="充值金额"
                  prop="ticket_money"
                  style="margin-bottom: 0px"
                >
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.ticket_money"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="充值备注"
                  prop="remark"
                  style="margin-bottom: 0px"
                >
                  <el-input
                    style="width: 100%"
                    v-model="form_ticket.remark"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-button
              @click="userLogTicketFlag = false"
              style="margin-left: 30px"
              >取 消</el-button
            >
            <el-button type="primary" @click="ticket_add()">确 定</el-button>
          </el-row>

          <el-table style="width: 100%" :data="tableData_ticket" height="80%">
            <el-table-column
              property="name"
              label="名称"
              width="150"
            ></el-table-column>
            <el-table-column
              property="dutyNum"
              label="税号"
              width="200"
            ></el-table-column>
            <el-table-column
              property="money"
              label="金额"
              width="150"
            ></el-table-column>
            <el-table-column
              property="createTime"
              label="开票时间"
              width="150"
            ></el-table-column>
            <el-table-column
              property="remark"
              label="开票备注信息"
            ></el-table-column>
          </el-table>
        </el-form>
        <template #footer>
          <!--<span class="dialog-footer">-->
          <!--<el-button @click="userLogTicketFlag = false">取 消</el-button>-->
          <!--<el-button type="primary" @click="editSubmit()">确 定</el-button>-->
          <!--</span>-->
        </template>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
import axios from "axios";
import { logUserList, logUpdate } from "@/api/log";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

export default {
  data() {
    return {
      tableData_ticket: [],
      userLogTicketFlag: false, //日志开票记录
      form_ticket: {}, //回显开票信息
      form: {
        id: null,
        name: "",
        oldMoney: "",
        money: "",
        createTime: "",
        remarks: null,
      },
      createTime: "",
      listType: 1,
      roleName: null,
      realname: null,
      adminSum_Z: "admin充值",
      adminSum_F: "admin扣款",
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.getList();
  },
  activated() {
    this.getList();
  },
  methods: {
    //开票提交确定
    ticket_add() {
      let params = {
        userLogId: this.form_ticket.id,
        money: this.form_ticket.ticket_money,
        remark: this.form_ticket.remark,
      };
      // console.log(params);
      axios
        .post("/userLogTicket/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.userLogTicketFlag = false; //弹出框
            this.getList();
          } else {
            this.$message.error(res.data.message);
            this.userLogTicketFlag = false;
            this.getList;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //双击
    ellDblclick(row, column, cell, event) {
      // this.userLogTicketFlag = true  //弹出框
      // this.rechargeRecordList.set(row.id, row)
      // this.$message.success(
      //     '已选择id' + row.id
      // );
      console.log(row);
      this.form_ticket = row;
      console.log(this.form_ticket);

      let params = {
        userLogId: row.id,
      };
      axios
        .post("/userLogTicket/findOne", params)
        .then((res) => {
          console.log(res.data.data);
          this.tableData_ticket = res.data.data; //代理编号

          this.userLogTicketFlag = true; //弹出框
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //确认按钮
    unsubscribe(row) {
      let Tstatis = row.row.status;
      if (0 === Tstatis) {
        Tstatis = 1;
      } else if (1 === Tstatis) {
        Tstatis = 0;
      } else {
        Tstatis = null;
      }
      let params = {
        id: row.row.id,

        status: Tstatis,
      };
      let res = logUpdate(params);
      res.then((res) => {
        this.$message.success(res.data.message);
      });
      if (2 === this.listType) {
        this.getList2();
      } else if (3 === this.listType) {
        this.getList3();
      } else {
        this.getList();
      }
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 展示
    getList() {
      this.listType = 1;
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        realname: this.form.realname,
        remarks: "充值金额",
        sta: this.sta,
        end: this.end,
      };
      let res = logUserList(params);
      res.then((res) => {
        if (res.data.success == false) {
          this.$router.push("/403");
        } else {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
      });
    },
    getList2() {
      this.listType = 2;
      if (this.createTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
      }
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        remarks: this.form.remarks,
        moneyType: 1,
      };
      axios
        .post("/log/sum-userlog", params)
        .then((res) => {
          // console.log(2222222222, res.data);
          if (res.data.statusCode == "50003") {
            Message.warning(res.data.message);
          } else {
            Message.success("查询成功");
          }
          this.tableData = res.data.data.list;
          this.pageTotal = res.data.total;
          this.adminSum_Z = res.data.data.sum;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getList3() {
      this.listType = 3;
      if (this.createTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
      }
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        remarks: this.form.remarks,
      };
      axios
        .post("/log/sum-userlog", params)
        .then((res) => {
          // console.log(3333333, res.data);
          if (res.data.statusCode == "50003") {
            Message.warning(res.data.message);
          } else {
            Message.success("查询成功");
          }
          this.tableData = res.data.data.list;
          this.pageTotal = res.data.total;
          this.adminSum_F = res.data.data.sum;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    reset() {
      this.form.realname = null;
      this.form.id = null;
      this.createTime = null;
      this.sta = null;
      this.end = null;
      this.adminSum_Z = "admin充值";
      this.adminSum_F = "admin扣款";
      this.getList();
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      if (this.form.id == "") {
        this.form.id = null;
      }
      if (this.createTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
      }
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#paylog {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 32%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  #ticket /deep/ .el-input__inner {
    // width: 99%;
    /*height: 99%;*/
  }
  #ticket .el-input {
    width: 30%;
    background-color: #98fd98;
  }
}
</style>